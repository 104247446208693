import buttonZoomIn from "./svg/buttonZoomIn.svg";


const Past = ({ TransitionState }) => {
  return (
    <div className="slide-past">
      <div className="past-box" >
        <button onClick={() => TransitionState()} className="past-close-bution">
          <img src={buttonZoomIn} alt="buttonZoomIn" />
        </button>

        <img width="972" height="1005" src="../static/img/past/control_freak/poster.jpg" alt="poster.jpg"/>
        <h1>CONTROL FREAK 2020</h1>
        <p>
          Oleksiy Sai’s exhibition CONTROL FREAK is about us. Deprived, at
          present, of our usual opportunities to make use of all our freedoms,
          we feel even greater doubt about our ability to control everything.
        </p>
        <p>
          The exhibition consists of almost the entire creative output of the
          artist. Its main device is almost completely inhibiting the viewer’s
          control of how s/he peruses the exhibition. This is a site-specific
          installation in the Shukhliada exposition environment.
        </p>
        <p>
          The exposition is deceptively spectacular, yet this is neutralized by
          the impossibility of a dignified stroll through the gallery, where one
          could experience the artworks through their physical perception and
          obtain, in particular, aesthetic impressions.
        </p>
        <p>
          While the exhibition is organized as a retrospective, a linear view is
          not available; you can only leave through one of its convolutions
          without catharsis.
        </p>
        <p>
          But the format of institutional control over the viewer’s experience
          becomes a fantasy in the conditions of online representation, where
          it’s enough for the viewer to open another tab to escape an
          uncomfortable situation.
        </p>
        <p>
          This exhibition offers a new experience, which may become a rather
          timely presentiment of our new life, new social connections, and new
          neuroses.
        </p>
        <br />
        <p>Curate by Ksenia Malykh.</p>
        <br />
        <p>
          The founders of Shukhliada exposition environment sincerely thank
          Daniel Yavorovych, and the Residency Program team at Ujazdowski Castle
          Centre for Contemporary Art for their assistance and partnership.
        </p>
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/1.jpg" alt="1.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/2.jpg" alt="2.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/3.jpg" alt="3.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/4.jpg" alt="4.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/5.jpg" alt="5.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/6.jpg" alt="6.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/7.jpg" alt="7.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/8.jpg" alt="8.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/9.jpg" alt="9.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/10.jpg" alt="10.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/11.jpg" alt="11.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/12.jpg" alt="12.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/13.jpg" alt="13.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/14.jpg" alt="14.jpg" />
        <img loading="lazy" width="1258" height="626" src="../static/img/past/control_freak/15.jpg" alt="15.jpg" />
        
        <img loading="lazy" width="972" height="1005" 
          src="../static/img/past/failing_to_raise_the_fallen_flag/poster.jpg"
          alt="poster.jpg"
        />
        <h1>Failing to raise the fallen flag 2019</h1>
        <p>Keep a distance, because we’re keeping it too.</p>
        <p>We stay silent, we scream, at a distance.</p>
        <p>Our flags, posters and banners are with us.</p>
        <p>Everyday.</p>
        <p>Our creations, position and declaration.</p>
        <p>That’s how we look from the (non)existent front.</p>
        <p>It’s the view from your safe distance.</p>
        <p>An imaginary broad coalition.</p>
        <p>Shoulder to shoulder.</p>
        <p>Together and alone.</p>
        <p>In the city and in the periphery.</p>
        <p>Yaroslav Futymskyi.</p>
        <br />
        <p>
          Artists: Yaroslav Futymskyi, Vadym Kharabaruk, Pavlo Kerestey, Vlodko
          Kostyrko, Daria Kuzmych, Anita Nemet, Natasha Shevchenko, Lubomyr
          Tymkiv, Ivan Svitlycnnyi and SVITER art group.
        </p>
        <br />
        <p>Curate by Yaroslav Futymskyi.</p>
        <br />
        {/* <a href={FileOne} className="fileLink">f</a> */}
        <img loading="lazy" width="1258" height="626"
          src="../static/img/past/failing_to_raise_the_fallen_flag/1.jpg"
          alt="1.jpg"
        />
        <img loading="lazy" width="972" height="1005" 
          src="../static/img/past/who_were_all_these_people/poster.jpg"
          alt="poster.jpg"
        />
        <h1>Who were all these people who saw that same landscape? 2018</h1>
        <p>
          Yaroslav Futymskyi in his artistic practice occasionally refers to
          landscape. This time the landscape is located on the outskirts of the
          small town of Poninka in the Polonne Raion of Khmelnytsky Oblast the
          place where the artist was born and where his family lives. The
          landscape in this case is not an object but rather the contextual
          environment where a workers strike was once attempted and immediately
          quashed. In 1905, workers at the paper mill, the principal employer in
          Poninka, tried to hold a meeting outside the village, in the forest
          along the river. This purely urban practice of trade union struggle
          was carried out here in an unnatural background, and immediately
          failed.
        </p>
        <p>Ksenia Malykh.</p>
        <p>
          When the historical dissolves in the landscape does the landscape
          become historical (political)? Where in this horizon, stones and river
          are the hooks that tell the story of an unrealized class struggle, the
          supposed trade union experience that vanished? How are these
          disturbances negated with time and washed away by the river into the
          past, silenced in memory? When did this flame ignite and how was it
          extinguished? Who were all these people who saw that same landscape?
        </p>
        <p>Yaroslav Futymskyi.</p>
        <br />
        <p>Curate by Ksenia Malykh.</p>
        <img loading="lazy" width="1258" height="626"
          src="../static/img/past/who_were_all_these_people/1.jpg"
          alt="1.jpg"
        />
        <img loading="lazy" width="1258" height="626"
          src="../static/img/past/who_were_all_these_people/2.jpg"
          alt="2.jpg"
        />
        <img loading="lazy" width="972" height="1005"
          src="../static/img/past/white_triptych/poster.jpg"
          alt="poster.jpg"
        />
        <h1>White triptych 2017</h1>
        <p>
          Emptiness, potential, new connotations of white. Triple fold, triple
          bend, three-part environment. Triptych. White triptych.
        </p>
        <p>Red/255 0 0/Up</p>
        <p>Green/0 255 0/Down</p>
        <p>Blue/0 0 255/Strange </p>
        <br />

        <p>
          Artists: Dobrynia Ivanov, Alevtina Kakhidze, Vitaliy Kokhan,
          Ujif_Notfound, Daniel Von Volckamer and Lesja Zajac.
        </p>
        <br />

        <p>Curate by Lera Polianskova, Max Robotov, Ivan Svitlychnyi.</p>
        <br />
        <p>Special thanksgiving: Marussia Zajac-Sydir and Khrystia.</p>

        <img loading="lazy" width="1258" height="626" src="../static/img/past/white_triptych/1.jpg" alt="1.jpg" />
        <br />
      </div>
    </div>
  );
};

export default Past;
