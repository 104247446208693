import buttonZoomIn from "./svg/buttonZoomIn.svg";

const Now = ({ TransitionState }) => {
  return (
    <div className="slide-now">
      <div className="now-box">
        <button onClick={() => TransitionState()} className="now-close-button">
          <img src={buttonZoomIn} alt="buttonZoomIn" />
        </button>
        <img
          width="972"
          height="1005"
          src="../static/img/now/poster.jpg"
          alt="poster.jpg"
        />
        {/* concept */}
        <h1>
          The painting from the photo that was taken on the big distance and
          then cropped.
        </h1>
        <p>
          This art piece is a part of a larger project which Khomenko prepared
          for her solo exhibition in the National Art Museum of Ukraine but had
          to put on hold after the start of the Russian invasion of Ukraine on
          24 February 2022. Working on her series depicting Ukrainian soldiers,
          the artist relied on the pictures she took from the window of her
          workshop in Kyiv. There was a military base nearby, and the artist
          could watch the soldiers from her ‘sniper’ vantage point on the sixth
          floor. Contrasting artistic vision and the view from the military
          scope, as well as the objectified perception of the human body and
          professional distance, Khomenko used her smartphone’s camera, zooming
          in on the soldiers’ figures, studying their bodies, and reflecting on
          an artist’s urge to watch people’s bodies and the sniper’s one to get
          inside them, by breaking their shell.
        </p>
        <p>
          When the full-on-war began in February 2022 and martial law was put
          into effect, taking pictures of military objects and soldiers at their
          stations was prohibited, and photography became not a tool but a
          dangerous weapon. Khomenko left Ukraine, and doesn’t work in the Kyiv
          studio anymore. As a result, the art piece has become accurate
          documentation of a historical period that divided the war into
          ‘before’ and ‘after’. Also, it may be regarded as a premonition of
          current events.
        </p>
        <p>
          Way back when the COVID-19 pandemic began and cultural life started
          becoming increasingly digitalised, the paradox of the art and its
          digital copies emerged as numerous online exhibitions, festivals, and
          art fairs popped up. The audience lost access to the bodily experience
          of accepting art within a feeling space and proportions. Paintings
          turned into small digital reproductions you can swipe through on your
          phone. Working on large-format paintings, Khomenko was keenly aware of
          the ‘physical art vs its digital copy’ issue.
        </p>
        <p>
          In her exhibition "The Painting From the Photo That Was Taken on the
          Big Distance and Then Cropped", the artist demonstrates her
          one-to-one-scale painting that lets the viewer experience the actual
          scale of the piece, albeit making it impossible to see the work in its
          entirety on the screen of a personal gadget—be it a smartphone or a
          PC—which can only show a small fragment of the painting. The
          exposition enables the viewer to scroll through the picture as if
          looking at it up close, which is much akin to how the painter
          approached her work when she used her smartphone’s camera to zoom in
          on the soldier’s figure, imagining that she was looking through a
          sniper scope.
        </p>

        <br/>

        <p>
          "The Painting From the Photo That Was Taken on the Big Distance and
          Then Cropped" project is supported as a result of the (re)connection
          UA Open Call, a pilot project implemented by the MOCA NGO in
          partnership with UNESCO and funded through the UNESCO Heritage
          Emergency Fund.
        </p>
        <p>
          The (re)connection UA aims at supporting the continuation of artistic
          creation and access to cultural life in Ukraine and values artists as
          essential actors in safeguarding Ukraine’s cultural diversity and
          identity and the role of cultural expressions for collective trauma
          healing, unity, and cohesion.
        </p>

        {/* bio */}
        <h1>Lesia Khomenko</h1>
        <p>
          (b. 1980 in Kyiv) is a multidisciplinary artist who reconsiders the
          role of painting—she deconstructs narrative images and transforms
          paintings into objects, installations, performances, or videos. Her
          interest lies in revealing tools of visual manipulation in the context
          of history-making and myth-making. She graduated from the National
          Academy of Fine Art and Architecture in Kyiv, 2004. A member of R.E.P.
          group since 2004, she is also a co-founder of curatorial union
          HUDRADA, a self-educational community based on interdisciplinary
          cooperation, 2008. Khomenko is an initiator and program director of
          the “Contemporary Art” course at the Kyiv Academy of Media Arts.
        </p>
        <p>
          Her works were shown in many exhibitions, <br />
          including at the Lviv Municipal Art Center, 2021 (solo);
          PinchukArtCentre, Kyiv, 2018 (solo);
          <br /> MNAC, Bucharest, 2016; The Museum of Contemporary Art Kiasma,
          Helsinki, 2015;
          <br /> CCA Zamek Ujazdowski, Warsaw, 2014;
          <br /> The Future Generation Art Prize@Venice2013;
          <br /> Kyiv Biennial 2012 (main project);
          <br /> National Art Museum of Ukraine, 2012;
          <br /> Mystetskyi Arsenal, Kyiv, 2011 (solo);
          <br /> Kalmar Konstmuseum, Sweden, 2011;
          <br /> White Box, New York, 2010;
          <br /> MUMOK, Vienna, 2009;
          <br /> Zacheta National Gallery of Art, Warsaw, 2008;
          <br /> De Appel, Amsterdam, 2008;
          <br /> Ukrainian pavilion at Venice Biennale, 2007 (a collaboration
          with Mark Titchner);
          <br /> and Kunsthalle Vienna, 2006.
        </p>
        <p>
          She was a finalist of the Pinchuk Art Prize in 2009, 2011, and 2013,
          and a 2016 finalist of the Kazimir Malevich Award, Kyiv. She lives and
          works in Kyiv – since March 2022, she has temporarily been in the
          West.
        </p>
        <br />
      </div>
    </div>
  );
};

export default Now;
