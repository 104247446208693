import "./App.css";

import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { isMobileOnly } from "react-device-detect";

import Project from "./Project";
import Info from "./Info";
import Past from "./Past";
import Now from "./Now";

import Drag from "./svg/intro_drag.svg";
import Logo from "./svg/logo.svg";

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// Now animation rulse for nav button
const navScaleR = (event) => {
  if (!isMobileOnly) {
    if (document.querySelector(".now").style.left) {
      document.querySelector(".now").style.left = null;

      document.querySelector(".info").style.left = null;
      document.querySelector(".info").style.width = null;
    } else {
      document.querySelector(".now").style.left = "50%";

      document.querySelector(".info").style.left = "50%";
      document.querySelector(".info").style.width = "50%";
    }
  }
};
// Info animation rulse for nav button
const navScaleD = (event) => {
  if (!isMobileOnly) {
    if (document.querySelector(".logo").style.top) {
      document.querySelector(".logo").style.top = null;
      document.querySelector(".info").style.top = null;

      document.querySelector(".past").style.height = null;
      document.querySelector(".past").style.top = null;

      document.querySelector(".now").style.height = null;
      document.querySelector(".now").style.top = null;
    } else {
      document.querySelector(".logo").style.top = "50%";
      document.querySelector(".info").style.top = "50%";

      document.querySelector(".past").style.top = "50%";
      document.querySelector(".past").style.height = "50vh";

      document.querySelector(".now").style.top = "50%";
      document.querySelector(".now").style.height = "50vh";
    }
  }
};
// Past animation rulse for nav button
const navScaleL = (event) => {
  if (!isMobileOnly) {
    if (document.querySelector(".logo").style.right) {
      document.querySelector(".logo").style.right = null;
      document.querySelector(".past").style.right = null;

      document.querySelector(".info").style.right = null;
      document.querySelector(".info").style.width = null;
    } else {
      document.querySelector(".logo").style.right = "50%";
      document.querySelector(".past").style.right = "50%";

      document.querySelector(".info").style.right = "50%";
      document.querySelector(".info").style.width = "50%";
    }
  }
};

function App() {
  // THIS HOOK USE FOR PAGE MOVE-ANIMATION
  const setTransitionAllFalse = () => {
    setTransitionNow(false);
    setTransitionInfo(false);
    setTransitionPast(false);

    document.querySelector(".now").style.left = null;
    document.querySelector(".now").style.height = null;
    document.querySelector(".now").style.top = null;

    document.querySelector(".info").style.left = null;
    document.querySelector(".info").style.width = null;
    document.querySelector(".info").style.top = null;
    document.querySelector(".info").style.right = null;

    document.querySelector(".logo").style.right = null;
    document.querySelector(".logo").style.top = null;

    document.querySelector(".past").style.right = null;
    document.querySelector(".past").style.height = null;
    document.querySelector(".past").style.top = null;
  };

  // THIS HOOK USE FOR ON/OFF PAGE
  //NOW
  const [stateNow, setStateNow] = useState(false);
  const handleStateNow = () => {
    if (stateNow) delay(500).then(() => setStateNow(!stateNow));
    else setStateNow(!stateNow);
  };
  // THIS HOOK USE FOR PAGE MOVE-ANIMATION
  const [stateTransitionNow, setTransitionNow] = useState(false);
  const handleTransitionNow = () => {
    var is_need_delay = false;
    if (stateTransitionInfo) {
      is_need_delay = true;
      handleTransitionInfo();
    } else if (stateTransitionPast) {
      is_need_delay = true;
      handleTransitionPast();
    }

    if (is_need_delay) {
      delay(500).then(() => {
        setTransitionNow(!stateTransitionNow);
        handleStateNow();
        navScaleR();
      });
    } else {
      setTransitionNow(!stateTransitionNow);
      handleStateNow();
      navScaleR();
    }
  };
  //END NOW
  //INFO
  const [stateInfo, setStateInfo] = useState(false);
  const handleStateInfo = () => {
    if (stateInfo) delay(500).then(() => setStateInfo(!stateInfo));
    else setStateInfo(!stateInfo);
  };
  // THIS HOOK USE FOR PAGE MOVE-ANIMATION
  const [stateTransitionInfo, setTransitionInfo] = useState(false);
  const handleTransitionInfo = () => {
    var is_need_delay = false;
    if (stateTransitionNow) {
      is_need_delay = true;
      handleTransitionNow();
    } else if (stateTransitionPast) {
      is_need_delay = true;
      handleTransitionPast();
    }

    if (is_need_delay) {
      delay(500).then(() => {
        setTransitionInfo(!stateTransitionInfo);
        handleStateInfo();
        navScaleD();
      });
    } else {
      setTransitionInfo(!stateTransitionInfo);
      handleStateInfo();
      navScaleD();
    }
  };
  //END INFO
  //PAST
  const [statePast, setStatePast] = useState(false);
  const handleStatePast = () => {
    if (statePast) delay(500).then(() => setStatePast(!statePast));
    else setStatePast(!statePast);
  };
  // THIS HOOK USE FOR PAGE MOVE-ANIMATION
  const [stateTransitionPast, setTransitionPast] = useState(false);
  const handleTransitionPast = () => {
    var is_need_delay = false;
    if (stateTransitionNow) {
      is_need_delay = true;
      handleTransitionNow();
    } else if (stateTransitionInfo) {
      is_need_delay = true;
      handleTransitionInfo();
    }
    if (is_need_delay) {
      delay(500).then(() => {
        setTransitionPast(!stateTransitionPast);
        handleStatePast();
        navScaleL();
      });
    } else {
      setTransitionPast(!stateTransitionPast);
      handleStatePast();
      navScaleL();
    }
  };
  //END PAST

  //ALL FALSE
  const handleAllStateTransmitionFalse = () => {
    if (statePast) {
      delay(500).then(() => setStatePast(false));
    }
    if (stateInfo) {
      delay(500).then(() => setStateInfo(false));
    }
    if (stateNow) {
      delay(500).then(() => setStateNow(false));
    }

    setTransitionAllFalse();
  };
  //END ALL FALSE

  //////////REWRITE THIS CODE (HARDCODE)
  // var something = document.querySelector('.nav');

  // something.onclick = function() {

  // };

  function SetFalseAllMenuComponents() {
    if (stateInfo || stateNow || statePast) handleAllStateTransmitionFalse();
  }

  // INTRO
  delay(3000).then(() => {
    document.querySelector(".intro-drag-box").style.display = "none";
  });

  const dragClick = () => {
    document.querySelector(".intro-drag-box").style.display = "none";
  };


  // ----------------------------------------------------------------------
  //FOR LESIA KHOMENKO EXHIBITION 
  // ----------------------------------------------------------------------
  // chek DPR and resize IMG size FOR LESIA KHOMENKO exhibition
  // + jamp to the center IMG by scrol
  useEffect(() => {

    var DPR = window.devicePixelRatio;
    var numW = 3401;
    var numH = 5811;
    var w = (numW * DPR).toString();
    var h = (numH * DPR).toString();
    var lesia_bodyW = document.querySelector(".lesia-kh-ex-grid-warper");
    var lesia_bodyH = document.querySelector(".lesia-kh-ex-grid-warper");
    if (lesia_bodyW != null) {
      lesia_bodyW.style.width = w + "px";
      lesia_bodyH.style.height = h + "px";
    }

    document
      .querySelector(".project")
      .scroll(
        document.querySelector(".lesia-kh-ex-grid-warper").offsetWidth / 2,
        document.querySelector(".lesia-kh-ex-grid-warper").offsetHeight / 2
      );
  }, []);

  // ----------------------------------------------------------------------

  return (    
    <div className="App">
      {/* _________cover!!!____________ !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
      {/* <div className="cover" >
        <p className="coming-soon" >Exhibition by</p>
        <p className="coming-soon">Lesia Khomenko</p>
        <p className="coming-soon">coming soon</p>
      </div> */}
      {/* ancomment intro!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            {/*   |
                  |
                  |
                  V   */}

      {/* INTRO */}
      <div className="intro-drag-box" onClick={dragClick} onTouchStart={dragClick} onWheel={dragClick}>
        <img src={Drag} alt="Drag" />
        <p>drag</p>
      </div>

      {/* logo */}
      <img className="logo" src={Logo} alt="Logo" />

      {/* NOW button*/}
      <button className="now" onClick={handleTransitionNow}>
        <p>now</p>
      </button>

      {/* INFO button */}
      <button className="info" onClick={handleTransitionInfo}>
        <p>info</p>
      </button>

      {/* PAST button*/}
      <button className="past" onClick={handleTransitionPast}>
        <p>past</p>
      </button>

      {/* NOW page*/}
      <CSSTransition
        in={stateTransitionNow}
        timeout={500}
        classNames="slide-now"
        unmountOnExit
      >
        <div>
          {stateNow ? <Now TransitionState={handleTransitionNow} /> : null}
        </div>
      </CSSTransition>

      {/* INFO page*/}
      <CSSTransition
        in={stateTransitionInfo}
        timeout={500}
        classNames="slide-info"
        unmountOnExit
      >
        <div>
          {stateInfo ? <Info TransitionState={handleTransitionInfo} /> : null}
        </div>
      </CSSTransition>

      {/* PAST page*/}
      <CSSTransition
        in={stateTransitionPast}
        timeout={500}
        classNames="slide-past"
        unmountOnExit
      >
        <div>
          {statePast ? <Past TransitionState={handleTransitionPast} /> : null}
        </div>
      </CSSTransition>

      {/* Project page */}
      <Project onClickFunction={SetFalseAllMenuComponents} />
    </div>
  );
}

export default App;
