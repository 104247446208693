import buttonZoomIn from "./svg/buttonZoomIn.svg";

const Info = ({ TransitionState }) => {
  return (
    <div className="slide-info">
      <div className="info-box">
        <button onClick={() => TransitionState()} className="info-close-button">
          <img src={buttonZoomIn} alt="buttonZoomIn" />
        </button>
        <div className="info-text-box">
          <p>
            Shukhliada exposition environment is an artist-run space, is a
            website, and its main mission is to be a platform for independent
            projects of curators and artists.
          </p>
          <p>
            The idea of the project arose in 2013 from new media artists from
            Ukraine—Liera Polianskova, Max Robotov and Ivan Svitlychnyi, when
            the artists decided that they wanted to create their own space,
            independent of political or financial aspects, and realized that the
            Internet platform could become such a space.
          </p>
          <p>
            In Ukrainian, "shukhliada" is a drawer, mainly a desk drawer. Often,
            artists write down experimental ideas and store in such
            "shukhliadas", to implement these ideas when new times and
            opportunities come. Therefore, the main task of Shukhliada
            exposition environment is to be a space for experiment, risk and
            search, where every idea finds a way for its implementation.
          </p>
          <p>
            At the moment, Shukhliada exposition environment work as an
            environment for addressing a variety of artistic and social tasks,
            from institutionality to inclusivity.
          </p>
        </div>
        <div className="info-contact-box">
          <a
            href="https://www.facebook.com/Shukhliada/"
            target="_blank"
            rel="noreferrer"
          >
            facebook
          </a>
          <a
            href="mailto:shukhliada@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            shukhliada@gmail.com
          </a>
          <a
            href="https://www.instagram.com/_shukhliada/"
            target="_blank"
            rel="noreferrer"
          >
            instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Info;
